import Icon from 'components/utils/Icon';
import useTranslation from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import NavItem from './NavItem';
import style from './sidebarFree.style';
import useSidebarMenu from '../../hooks/useSidebarMenu';
import { Link } from 'components/utils/Link';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function SidebarFree({ menuOpen, menuOpenHandler }) {
  const { TRANSLATIONS, locale } = useTranslation();
  const router = useRouterWithLang();
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const handleRouteChange = useCallback(
    (route) => {
      router.push(route);
      if (menuOpen) menuOpenHandler();
    },
    [menuOpen, menuOpenHandler, router]
  );
  const { menuItems } = useSidebarMenu();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];

  return (
    <div className={`relative`}>
      <div
        className={menuOpen ? 'overlay' : ''}
        onClick={() => {
          if (menuOpen) {
            menuOpenHandler();
          }
        }}
      />
      <div>
        <aside className={`sidenav ${menuOpen ? 'active' : 'inactive'}`}>
          <div className="sidenav__top-section relative">
            {isLoggedIn && (
              <div
                className="user-menu-title d-f fd-c fw-w  g-5"
                onClick={() => {
                  handleRouteChange('/profile/general');
                }}
              >
                <p>
                  {TRANSLATIONS.home.titleRoleUSer} {user?.name}
                </p>
                <div>
                  <span className={`role-user`}>{user?.roles?.[0].name}</span>
                </div>
              </div>
            )}
            {!isLoggedIn && (
              <>
                <div className="menu-wrap-center-logo ai-c d-f"></div>
              </>
            )}
            <div
              className="close-btn"
              onClick={menuOpenHandler}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                type="x"
                style={{ fontSize: 26, color: 'var(--foreground)' }}
              />
            </div>
          </div>
          <div>
            <ul className="menu">
              <h5>{TRANSLATIONS.menuItem.ourProducts}</h5>
              {menuItems
                ?.filter((item) => !item?.hidden)
                ?.map((item, i) => (
                  <NavItem
                    key={item.label}
                    item={item}
                    handleRouteChange={handleRouteChange}
                  />
                ))}
            </ul>
            <div>
              <ul className="menu ">
                <h5>{TRANSLATIONS.menuItem.ourServices}</h5>
                <Link href="/pricing">
                  <li>
                    <div>
                      <div className={'item'}>
                        {TRANSLATIONS.menuItem.dropshipping}
                      </div>
                    </div>
                  </li>
                </Link>
                <a
                  href={
                    locale === 'it_IT'
                      ? 'https://aidify.cx/it'
                      : 'https://aidify.cx'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>
                    <div>
                      <div className={'item'}>
                        {TRANSLATIONS.menuItem.aiChatbot}
                        <span
                          style={{
                            backgroundColor: '#005eff',
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            padding: '2px 6px',
                            borderRadius: '6px',
                            marginLeft: '5px',
                          }}
                        >
                          {TRANSLATIONS.menuItem.free}
                        </span>
                      </div>
                    </div>
                  </li>
                </a>
                {locale === 'en_US' && (
                  <Link href="/become-distributor-duca-di-morrone">
                    <li>
                      <div>
                        <div className={'item'}>
                          {TRANSLATIONS.menuItem.eFranchising}
                        </div>
                      </div>
                    </li>
                  </Link>
                )}
              </ul>
            </div>
          </div>
        </aside>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default SidebarFree;
