import useTranslation from './useTranslation';
import { useSelector } from 'react-redux';

const useSubheaderMenu = () => {
  const { TRANSLATIONS, locale } = useTranslation();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const PERMISSIONS = useSelector((state) => state.permissions);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const user = useSelector((state) => state.user);

  const menuItems = [
    {
      label: TRANSLATIONS.menuItem.man,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?gender=men&attributes=canale-Moda&availability=1`
        : `/product?gender=men&attributes=canale-Moda&availability=1`,
      hidden: locale !== 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.woman,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?gender=women&attributes=canale-Moda&availability=1`
        : `/product?gender=women&attributes=canale-Moda&availability=1`,
      hidden: locale !== 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.moda,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-Moda`
        : `/product?availability=1&attributes=canale-Moda`,
      hidden: locale === 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.catalog,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-Moda`
        : `/product?availability=1&attributes=canale-Moda`,
      hidden: locale !== 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.sexyShop,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-sexy-shop`
        : `/product?availability=1&attributes=canale-sexy-shop`,
      hidden:
        (user?.countryCode === 'IT' && !user?.ignoreRestrinctions) ||
        locale === 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.casa,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-casa`
        : `/product?availability=1&attributes=canale-casa`,
      hidden:
        (user?.countryCode === 'IT' && !user?.ignoreRestrinctions) ||
        locale === 'it_IT',
    },
    {
      label: TRANSLATIONS.menuItem.brands,
      to: '/brands',
    },
    {
      label: TRANSLATIONS.menuItem.dropshipping,
      to: '/pricing',
    },
  ];

  return {
    menuItems,
  };
};

export default useSubheaderMenu;
